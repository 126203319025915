import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="./profile.png" style={{
      "width": "300px",
      "borderRadius": "50%",
      "height": "300px",
      "boxShadow": "0px 0px 24px 2px",
      "color": "#333"
    }}></img>
    <h2 style={{
      "color": "#47bb78"
    }}>அகன்வீ வருண்குமார்</h2>
    <h3 style={{
      "color": "lightblue"
    }}>Aganvee Varunkumar</h3>
/uh-gun-vee/
    <br />
    <br />
    <div>
  அகன் = heart / house / us
  <br />
  வீ = flower that is fully blossomed
  <br />
  அகன்வீ = flower of our house
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      